// JSDoc (http://usejsdoc.org/)
/** @module RevisionEsquemaService */

export default class revisionEsquemaService{
    /**
     * @param axios Axios Instance
     */
     constructor(axios){
        this.axios =  axios;
    }

     /**
     * Crear un esquema
     * @param {json} data Parámetros de Entrada.
     * @param {Integer} data.rve_codrse  Codigo de seccion
     * @param {Integer} data.rve_codrec  Codigo de criterio escala 
     * @param {Boolean} data.rve_req_comentario  Indicador si requiere comentario 
     * @param {Boolean} data.rve_blq_inicial  Indicador si pertenece solo al bloque inicial
     * @param {Integer} data.rve_sec_orden Orden de secuencia
     * @param {String} data.rve_version  Indicador si requiere comentario  
     * @return {String}  Version
     */
    async createEsquema(data){
        return this.axios.post('/RevisionEsquema/Create', data, {
            header: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data);
    }

    async getEsquema(version){
        return this.axios.get(`/RevisionEsquema/GetEsquema/${version}`).then(res => res.data)
    }
    
    async getAllEsquema(ins){
        return this.axios.get(`/RevisionEsquema/GetAllEsquema/${ins}`).then(res => res.data)
    }



     /**
     * Eliminar el criterio con sus  escalas
     * @param {json} data Parámetros de Entrada.
     * @param {Integer} data.rve_codrse  Codigo de seccion
     * @param {Integer} data.rve_codrec  Codigo de criterio escala 
     * @param {String} data.rve_version  Indicador si requiere comentario 
     * @return {String} Mensaje de repuesta
     */
      async deleteEsquema(data){
        return this.axios.delete(`/RevisionEsquema/${data.rve_codrec}`, { 
            data:
            {
                rve_codrse :  data.rve_codrse,
                rve_version : data.rve_version
            }
        }, 
        {
            header: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data);
    }

    /**
     * Imprimir/Extraer archivo de revision formato
     * @param {String} rve_version  Codigo de version
     * @return {blob} blobObject
     */
     async printEsquema(rve_version){
        return this.axios({
            url: `/RevisionEsquema/Print/${rve_version}`,
            method: 'GET',
            responseType: 'blob', // important
        }).then((res) =>res);
    }


    async getAllCriterio(){
        return this.axios.get("/RevisionEsquema/Criterio/GetAll").then(res => res.data);
    }

    async getAllCriterioEscala(codCriterio){
        return this.axios.get(`/RevisionEsquema/Criterio/${codCriterio}/GetEscala`).then(res => res.data);
    }

    /**
     * Eliminar el criterio escala
     * @param {Integer} codigo  Codigo criterio-escala
     * @return {String} Mensaje de repuesta
     */
     async deleteCriterioEscala(codigo){
        return this.axios.delete(`/RevisionEsquema/Criterio/Escala/${codigo}`,
        {
            header: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data);
    }

    /**
     * Crear/editar una Criterio
     * @param {json} data Parámetros de Entrada.
     * @param {Integer} data.rce_enunciado  Descripcion de criterio
     * @param {Integer} data.rce_activo  Estado 
     * @param {String}  data.rce_codigo  Codigo de criterio OUT/IN
     * @return {json} JsonObject
     */
     async upsertCriterio(data){
        var result =  await this.axios.post('/RevisionEsquema/Criterio', data, {
            header: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data);

        if(result.rce_codigo > 0)
            result.escalaList = await this.getAllCriterioEscala(result.rce_codigo)
        return result;
    }


    async getAllEscala(){
        return this.axios.get('/RevisionEsquema/Escala/GetAll').then(res => res.data)
    }

    /**
     * Crear/editar una escala
     * @param {json} data Parámetros de Entrada.
     * @param {String} data.ree_descripcion  Descripcion de escala
     * @param {String} data.ree_agrupador  Agrupador codigo
     * @param {Integer}  data.ree_codigo  Codigo de escala OUT/IN
     * @return {json} JsonObject
     */
    async upsertEscala(data){
        return this.axios.post('/RevisionEsquema/Escala', data, {
            header: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data)
    }

    
    async getAllSeccion(){
        return this.axios.get('/RevisionEsquema/Seccion/GetAll').then(res => res.data)
    }

    /**
     * Crear/editar una seccion
     * @param {json} data Parámetros de Entrada.
     * @param {String} data.rse_nombre  Descripcion de seccion
     * @param {String} data.rse_activo  Estado de seccion
     * @param {Integer}  data.rse_codigo  Codigo de Seccion OUT/IN
     * @return {json} JsonObject
     */
     async upsertSeccion(data){
        return this.axios.post('/RevisionEsquema/Seccion', data, {
            header: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data)
    }
}