<template>

    <div class="layout-wrapper layout-static layout-static-sidebar-inactive layout-horizontal layout-theme-light ">
        <div class="menu-toolbar">
            <Menubar :model="null" class="border-noround">
                <template #start>
                    <router-link to="Evaluacion">
                        <img alt="logo" src="@/assets/layout/images/logo-horizontal-white.png" height="40" class="p-mr-2">
                    </router-link>
                </template>
                <template #end>
                    <AppProfile :hiddenButtons="true"/>
                </template>
            </Menubar>
            <!--BEGIN: Title Header -->
            <div class="bg-white  ">
                <div class="flex justify-content-center align-items-center py-3" >
                    <h5 class="font-bold m-0 text-primary uppercase text-center text-lg md:text-xl">ESCALA DE ESTIMACIÓN DEL DESEMPEÑO DOCENTE</h5>
                </div>
                
            </div>
            <!-- Alert -->
            <div class="bg-light-danger py-2 ">
                <h6 class="font-bold my-0 py-1 text-center"> 
                    <span> ⚠️ </span>¡No salga ni actualice el navegador  
                    <span class="text-primary">Seguimiento a la labor</span>! Su opinión es importante para mejorar la calidad enseñanza-aprendizaje  
                    <a href="javascript:;" class="text-primary"> UGB </a> </h6>
            </div>
        </div>

        <ErrorPage v-if="IsError"  :message="catchError" :showButton="false"/>

        <!-- Layout Content -->
        <div class="layout-main-container px-0  mt-8" ref="header" v-else>
            <!-- Content -->
            <div class="layout-main px-5 mt-5 md:mt-3" >
                <div class="grid" data-tab="tab-test">
                    
                    <!--BEGIN: TAB Home -->
                    <div class="col-12 md:col-12 lg:col-9 xl:col-8 xl:col-offset-2  lg:col-offset-2" ref="seccion" data-tab="tab-test" v-if="CURRENT_TAB == TAB.HOME">
                        <div class="grid">
                            <!-- header info -->
                            <div class="col-12 md:col-5">
                                <div class="p-card px-3">
                                    <div class="flex flex-column align-items-center">
                                        <div class="pb-2 mb-1">
                                            <div class="flex flex-column align-items-center">
                                                <div class="symbol symbol-120 symbol-circle symbol-primary">
                                                    <div class="symbol-label">
                                                        <img src="@/assets/layout/images/blank.png" style="height: 75% !important;" alt="">
                                                    </div>
                                                </div>
                                                <a href="javascript:;"  class="font-bold text-center text-gray-700 hover:text-primary pt-4 pb-1 text-lg">
                                                    {{estudiantePerfil.nombres}} {{estudiantePerfil.apellidos}}
                                                </a>
                                                <div class="font-bold text-gray-500 text-center text-sm pb-3">{{estudiantePerfil.carrera}}, {{estudiantePerfil.carnet}} </div>
                                            </div>

                                            <div class="pt-1">
                                                <p class="text-gray-700 text-center text-lg m-0 pb-4">
                                                    {{estudiantePerfil.facultad}}
                                                </p>
                                                
                                                <div class="flex align-items-center pb-2"> 
                                                    <div class="symbol symbol-45 symbol-light mr-4">
                                                        <span class="symbol-label">
                                                            <LayoutGridIco  :size="'svg-icon-2x'"/>
                                                        </span>
                                                    </div>
                                                    
                                                    <div class="flex flex-column flex-grow-1">
                                                        <a href="javascript:;" class="text-gray-700 hover:text-primary text-base font-bold py-1">Asignaturas Inscritas</a>
                                                        <span class="text-muted font-semibold text-sm">Ciclo vigente</span>
                                                    </div>

                                                    <span class="font-bold label  label-light-primary text-base label-inline px-3 py-3">{{materiasInscritasList.length}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--  body test list -->
                            <div class="col-12 md:col-7">
                                <div class="p-card px-4">
                                    <div class="p-card-title">
                                        <div class="card-header">
                                            <div class="card-title">
                                                <div class="flex flex-column">
                                                    <h5 class="ml-3 my-0 font-semibold text-gray-900 text-lg flex align-items-center">Asignaturas para evaluación</h5>
                                                    <div class="text-gray-500 text-sm font-semibold mx-3">Tiempo estimado para realizarla menos de <span class="text-primary">10 mins</span> </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>

                                    <div class="flex flex-column mt-5">
                                        <DataTable :value="guiaDesempenoList"  responsiveLayout="scroll" stripedRows>
                                                    <template #empty>
                                                        <p  class="font-italic text-center">Al parecer no hay evaluaciones activas que realizar.</p>
                                                    </template>
                                                    <Column header="Asignaturas">
                                                        <template  #body="{data}">
                                                            <div class="flex ">
                                                                <div class="h-100 p-0 mr-1 bg-indigo-500 border-round" style="width:4px" ></div>
                                                                <div class="flex flex-column flex-shrink-1 flex-grow-1">
                                                                    <div class="flex align-items-center ">
                                                                        <span class="mr-3 hidden  md:block" >
                                                                            <i class="pi pi-book text-4xl  text-primary"></i>
                                                                        </span>
                                                                        
                                                                        <div class="flex flex-column">
                                                                            <a href="javascript:;" class=" text-primary font-semibold uppercase" @click="startEvaluacion_onClick(data)">{{data.materia_nombre}}</a>
                                                                            <span class=" text-sm md:text-sm font-semibold"> {{data.horario_descripcion}}</span>
                                                                            <span class="text-sm text-gray-700 font-semibold">{{data.empleado_nombre}}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="flex flex-row align-items-center">
                                                                    <a href="javascript:;"  class="p-button p-button-icon-only p-button-rounded p-button-text" tag="button" @click="startEvaluacion_onClick(data)" v-tooltip.top="isSaved ? 'Ir a resultados' : 'Iniciar evaluación'"> 
                                                                        <i class="pi pi-arrow-right  text-4xl p-button-icon"></i>
                                                                    </a>                                            
                                                                </div>
                                                            </div>
                                                        </template>
                                                    </Column>
                                        </DataTable>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!--BEGIN: TAB Test -->
                    <div class="col-12 md:col-12 lg:col-9 xl:col-8 xl:col-offset-2  lg:col-offset-2" ref="seccion" data-tab="tab-test" v-if="CURRENT_TAB == TAB.TEST">
                        <!--Body Test : Header -->
                        <div class="p-card">
                            <div class="p-card-title mb-0 text-primary">
                                <div class="flex align-items-center justify-content-center">
                                    <span class="pi pi-user mx-2 text-4xl "></span>
                                    <span class="text-lg uppercase">{{selectedGuiaDesempeno.empleado_nombre}}</span>
                                </div>
                            </div>
                            <div class="p-card-subtitle text-center">
                                 💻  {{selectedGuiaDesempeno.materia_nombre}}
                            </div>
                            <Divider />
                            <div class="p-card-content pt-0  md:mx-8">
                                <div class="flex justify-content-center" v-if="loadingSchema">
                                <ProgressSpinner tyle="width:250px;height:250px" strokeWidth="4" fill="var(--surface-ground)" animationDuration="1.5s"/>
                                </div>
                                <div class="ml-1" v-else>
                                    <div class="flex justify-content-center py-2" >
                                        <h5 class="font-bold text-gray-600 uppercase text-primary">{{currentSeccion.seccion}}</h5>
                                    </div>
                                    <!--Body Test : Begin Content -->
                                    <div class="mb-6 py-2 px-4"   :class="{'border-dashed-custom border-danger bg-light-danger-50' : itemPediente == idxCri}" 
                                    v-for="criterio,idxCri in currentSeccion.criterioList" :key="criterio" :ref="'crit' + idxCri">
                                        <div class="flex justify-content md:mx-8"  >
                                            <h5 class="font-semibold text-lg ">
                                                <span class="mr-2 text-danger">{{criterio.correlativo}}. </span>
                                                <span class="text-gray-700">{{criterio.rce_enunciado}}</span>
                                            </h5>
                                        </div>
                                        <div class="flex justify-content-center my-2 md:mx-5" 
                                            :class="criterio.escalaList.filter(x=> x.ree_agrupador == 'MULTI_DEFAULT').length > 0 ? 'flex-row' : 'flex-column'" >
                                            <div class="flex"  v-for="escala,idx in criterio.escalaList" :key="escala">
                                                <!-- Select Option -->
                                                <div class="my-2 w-full" v-if="escala.ree_agrupador != 'MULTI_DEFAULT'">
                                                    <div class="option  border-round  p-3  flex justify-content-center text-lg text-primary font-bold text-center"
                                                        @click="selectedEscala_onClick({ criterio: criterio, index: idxCri} ,escala)"
                                                        :class="escala.selected ? 'hover:bg-blue-300 hover:text-primary bg-blue-500 text-white ' : 'bg-gray-200 hover:bg-gray-300'">
                                                        {{vowel(idx)}} {{escala.ree_descripcion}}
                                                    </div>
                                                </div>
                                                <a href="javascript:;" v-else @click="selectedEscala_onClick({ criterio: criterio, index: idxCri} ,escala)">
                                                    <component :is="satisfactionRateIco(idx,escala)"  />
                                                </a>
                                            </div>

                                        </div>
                                    </div>
                                    <h5 class="mt-3 font-bold uppercase text-center">- COMENTARIOS -</h5>
                                    <Divider />
                                    <div class="flex flex-column">
                                        <div class="p-fluid">
                                            <div class="field">
                                                <label for="rev_logros " class="font-bold text-primary">LOGROS *</label>
                                                <Textarea  ref="rev_logros" id="rev_logros" v-model.trim="comentario_logros" rows="5" :readonly="isSaved"
                                                :autoResize="true"  placeholder="Describa de forma sintética, los logros identificados (aspectos positivos, fortalezas, etc)" />
                                                <div class="flex flex-row" :class="{'text-danger': (comentario_logros > 2500)}">
                                                    <small class="p-error" v-if="submittedSeccion && !comentario_logros.trim()">* Comentario requerido.</small>
                                                    <small class="flex-grow-1 flex flex-column flex-shrink-1 align-items-end" :class="{'text-danger':comentario_logros.length < 50 }">{{comentario_logros.length}}/2500</small>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="p-fluid">
                                            <div class="field">
                                                <label for="rev_logros " class="font-bold text-primary">ASPECTOS A MEJORAR *</label>
                                                <Textarea  ref="rev_logros" id="rev_logros" v-model.trim="comentario_mejoras" rows="5" :readonly="isSaved"
                                                :autoResize="true"  placeholder="De manera resumida, relacione qué aspectos se deben mejorar (de cada indicador)" />
                                                <div class="flex flex-row" :class="{'text-danger': (comentario_mejoras > 2500)}">
                                                    <small class="p-error " v-if="submittedSeccion && !comentario_mejoras.trim()">* Comentario requerido.</small>
                                                    <small  class="flex-grow-1 flex flex-column flex-shrink-1 align-items-end" :class="{'text-danger':comentario_mejoras.length < 50 }" >{{comentario_mejoras.length}}/2500</small>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <!--Body Test : End Content -->

                                    <!--Footer Test: Buttons -->
                                    <div class="flex justify-content-between py-3 " >
                                        <Button :label="indexSeccion == 0 ? 'Inicio' : 'Anterior'" icon="pi pi-arrow-left" class="p-button-outlined"  @click="previousSeccion_onClick()"/>
                                        <Button :label="indexSeccion + 1 < this.revSeccionEsquemaList.length ? 'Siguiente' : 'Finalizar'" icon="pi pi-arrow-right" class="p-button-outlined"  @click="nextSeccion_onClick()" />
                                        <!-- <Button :label="isSaved ? 'Terminado' : 'Finalizar'" class="p-button-outlined" @click="submitDesempeno_onClick()" v-if="indexSeccion + 1 == this.revSeccionEsquemaList.length"/> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--END: Body Test -->

                    <!--Begin: Result -->
                    <div class="col-12 md:col-9 md:col-offset-2 lg:col-6 lg:col-offset-3" data-tab="tab-result" v-if="CURRENT_TAB == TAB.RESULT">
                        <div class="p-card ">
                            <div class="flex flex-column align-items-center">
                                <SuccessAnimationIco />
                                <h5 class="font-semibold text-lg text-primary py-0 my-0 text-center ">Se ha finalizado la evaluación de la escala de estimación del desempeño docente.</h5>
                                <span class="font-semibold  text-lg text-gray-900 my-2 text-center">¡Gracias por ayudarnos a fortalecer el proceso de enseñanza-aprendizaje!</span>
                                <Button label="Ir a inicio " icon="pi pi-home" class="p-button-outlined my-5"  @click="goHome_onClick()" />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <AppFooter />
        </div>
    </div>
</template>

<script>

import {SuccessAnimation,LayoutGrid, SatisfactionAngry, SatisfactionUpset, SatisfactionNeutral,SatisfactionHappy, SatisfactionExcited,SatisfactionCheck} from "@/components/svg/_svgHelper.js"
import AppFooter from '@/layout/AppFooter.vue';
import AppProfile from "@/layout/AppProfile"
import ErrorPage from "@/components/Pages/Error"

import GuiaDocenteService from '@/service/guiaDocenteService'
import RevisionEsquemaService from "@/service/revisionEsquemaService"
import PlanDidacticoService from '@/service/planDidacticoService'

import methods from "@/utilities/generalMethods.js"


const TAB = {
    HOME : 1,
    TEST : 2,
    RESULT : 3
}

//SET INSTRUMENTO: Escala Estimación Desempeño Docente
const INSTRUMENTO =  "INS_EEDD" 
const delay = ms => new Promise(res => setTimeout(res, ms));


export default {
    revisionEsquemaService : null,
    guiaDocenteService : null,
    planDidacticoService : null,
    data(){
        return {
            //Perfil de estudiante 
            estudiantePerfil: {},
            parametroGeneral : {},
            materiasInscritasList: [],

            guiaDesempenoList: [],
            selectedGuiaDesempeno : {},

            revSeccionEsquemaList: [],

            //MasterData
            desempenoDocenteComentarioList: [],
            desempenoDocenteRevisionList : [],

            comentario_logros : "",
            comentario_mejoras : "",
            cicloActivo  : "",
            //ControlData
            indexSeccion : -1,
            submittedSeccion : false,
            loadingSchema : false,

            TAB :  TAB,
            CURRENT_TAB : TAB.HOME,
            IsError : false,
            catchError :"",
            itemPediente : -1

        }
    },
    created(){
        this.indexSeccion =  -1 
        this.revisionEsquemaService = new RevisionEsquemaService(this.axios);
        this.guiaDocenteService = new GuiaDocenteService(this.axios);
        this.planDidacticoService =  new PlanDidacticoService(this.axios)

        this.Codigo =  this.$route.params.id
    },

    async mounted(){
        try{            
            //Get data for evaluation
            await this.fx_Init();
            this.IsError =  false;
            this.catchError =  ""
            
        }catch(ex){
            var msg = methods.messageCode(ex)
            this.$toast.add({severity:'error', summary:'¡OOOPS!', detail:msg, life: 3500});
            this.IsError =  true; this.catchError =  msg;

            
        }
        if(this.IsError) return;



        //Get data for Materias Inscritas
        try{ 
            this.materiasInscritasList = await this.guiaDocenteService.getMateriasInscritas(
                this.parametroGeneral.cil_codigo,
                this.parametroGeneral.cic_codigo,
                this.estudiantePerfil.codigo);

            var materias = this.materiasInscritasList
            if(materias.length > 0){
                var inscritasList =  materias.map((item)=>{
                    return { 
                        car_codigo :  item.codigoCarrera, 
                        pla_codigo :  item.codigoPlan, 
                        plm_codmat : item.codigoMateria,
                        hor_codigo : item.codigoHorario
                    }
                })

                this.guiaDesempenoList  =  await this.guiaDocenteService.getDesempenoDocenteByMateria(JSON.stringify(inscritasList))
            }
        }
        catch(e){
            msg = methods.messageCode(e)
            let status =  "!Error!" //status
            if(e.response){
                status = `!Error  ${e.response.status} ${e.response.statusText}!  La sesión a finalizado, vuelva al portal y intente nuevamente desde el botón de acceso al Seguimiento.`
                if(e.response.status == 401){
                    this.IsError =  true; 
                    this.catchError =  status;
                }
            }
            this.$toast.add({severity:'error', summary:status, detail:msg, life: 3500});
        }
    },


    methods:{
        async fx_goToPosition(index){
             //go to next position
            var element = this.$refs['crit'+index];
            var minus = 150
            var top = element.offsetTop;
            window.scrollTo(0, top - minus);
        },
        async fx_Init(){
            //Get router parameter
            var keyList = this.$cookies.keys();
       
            if(keyList){
                var ssoList = undefined;
                //Get cookie status
                await keyList.forEach(async(element) => {
                    var decodedStr = atob(element);
                    if(decodedStr === "ssostd"){
                        var cookieContent =  atob(this.$cookies.get(element))
                        var jsonCookie = JSON.parse(cookieContent)
                        ssoList = jsonCookie
                    }else{
                        throw "Se ha roto el vínculo de inicio de sesión desde el origen."
                    }
                });    
                
                //Get List for cookie
                if(typeof ssoList === 'object'){
                    this.axios.defaults.headers.common['Authorization'] = 'Bearer ' + ssoList.token;
                    //param : ssoList (Object) =>  { token : Object , persona : Array , cil_codigo :  Integer, cic_codigo : Integer }
                    this.estudiantePerfil = ssoList.persona;
                    this.parametroGeneral["cic_codigo"] = ssoList.cic_codigo
                    this.parametroGeneral["cil_codigo"] = ssoList.cil_codigo
                }else{
                    throw "Los datos proporcionados por la aplicación no son válidos para este sistema. Regrese al portal y vuelva a intentarlo. "
                }
            }else{
                throw "Al parecer el vínculo no fue creado correctamente."
            }
        },


        async fx_getRevision(codigo){
            this.loadingSchema  = true

            this.desempenoDocenteRevisionList  = []
            this.desempenoDocenteComentarioList = []

            //Load data for previous result if exists
            await this.guiaDocenteService.getRevisionByDesempeno(codigo, INSTRUMENTO,this.estudiantePerfil.codigo).then(res=>{
                this.desempenoDocenteRevisionList  =  res.revision
                this.desempenoDocenteComentarioList =  res.comentario
            })

             //Load schema inst 4 => Escala de Estimación del Desempeño Docente
            var versionEsquema = "VER1_REV2022-4" //Cambiar por parametro General
            if(this.desempenoDocenteRevisionList.length > 0)
                versionEsquema = this.desempenoDocenteRevisionList[0].rve_version

            this.revSeccionEsquemaList   = await this.revisionEsquemaService.getEsquema(versionEsquema)
            this.indexSeccion =  0

             await delay(1000);
            this.loadingSchema =  false
        },

        goHome_onClick(){
            this.CURRENT_TAB  = TAB.HOME
            this.indexSeccion = -1
            this.desempenoDocenteRevisionList = []

            setTimeout(function () {
                window.scrollTo(0, 0);
            },2);
        },

        async startEvaluacion_onClick(data){
            this.CURRENT_TAB = this.TAB.TEST
            this.selectedGuiaDesempeno =  data;
            await this.fx_getRevision(data.codigo); //Revisión previa
            
        },
        //go to next
        async nextSeccion_onClick(){
            //#region Validations
            this.itemPediente = -1
            this.submittedSeccion =  true
            var totalSelected  = this.revSeccionEsquemaList[this.indexSeccion].criterioList.filter(x => x.escalaList.some(y=> y.selected)).length

            var totalCriterioSeccion =  this.revSeccionEsquemaList[this.indexSeccion].criterioList.length;
            if(totalSelected < totalCriterioSeccion){
                this.$toast.add({severity:'warn', summary:'¡Datos no válidos!', detail:"Completar la sección antes de continuar.", life: 5000});
                var indexCriterio =  this.currentSeccion.criterioList.findIndex(x=> !x.escalaList.some(y=> y.selected))
                this.itemPediente = indexCriterio
                this.fx_goToPosition(indexCriterio);
                return;
            }else if (!this.comentario_logros || !this.comentario_logros.trim() || !this.comentario_mejoras || !this.comentario_mejoras.trim() )
            {
                this.$toast.add({severity:'warn', summary:'¡Datos no válidos!', detail:"Completar los comentarios de la sección.", life: 5000});
                return;
            }
            //#endregion Validations

            //Verify if exists comment on list
            var indexExists  =  this.desempenoDocenteComentarioList.some(x=> x.ddc_codrse == this.revSeccionEsquemaList[this.indexSeccion].codseccion);
            if(!indexExists){
                //add new coments
                this.desempenoDocenteComentarioList.push({
                    ddc_codigo : 0,
                    ddc_codddo : this.selectedGuiaDesempeno.codigo,
                    ddc_codrse : this.revSeccionEsquemaList[this.indexSeccion].codseccion,
                    ddc_logro : this.comentario_logros,
                    ddc_inst : INSTRUMENTO,
                    ddc_mejora : this.comentario_mejoras,
                    ddc_codper: this.estudiantePerfil.codigo,
                    ddc_codusr_creador : 0 //this.$store.getters.getUserId
                })
            }

            //go to next seccion
            var totalSeccion =  this.revSeccionEsquemaList.length;
            if(this.indexSeccion < totalSeccion - 1){
                this.indexSeccion++;
            }else{
               await this.submitDesempeno_onClick()
            }

            // //restart values
            this.submittedSeccion =  false
            this.comentario_logros =  ""
            this.comentario_mejoras =  ""
        },

        //go to previous seccion
        previousSeccion_onClick(){
            if(this.indexSeccion != 0)
                this.indexSeccion--;
            else
                this.goHome_onClick()
        },

        //click selected
        async selectedEscala_onClick(refValue,eEscala){
            this.itemPediente = -1
            if(this.isSaved) {
                //can´t edit
                return;
            }
            this.restartEscala_onClick(refValue.criterio)

            await this.fx_goToPosition(refValue.index)
            
            //set other value to <false>
            eEscala.selected = true;
        },

        restartEscala_onClick(criterio){

            //if exists
            if(this.isSaved){
                return;
            }
            //unselect & unhidden items
            criterio.escalaList.forEach((element)=>{
                element.selected =  false
                element.hidden =  false
            })

        },

        async  submitDesempeno_onClick(){
            try{
                setTimeout(function () {
                    window.scrollTo(0, 0);
                },2);
                if(this.isSaved){
                    this.CURRENT_TAB = TAB.RESULT
                    this.$toast.add({severity:'warn', summary:'¡No guardado!', detail:"Ya se ha realizado con anterioridad", life: 5000});
                    return;
                }
                //Prepare data
                var dataToSend = []

                this.revSeccionEsquemaList.forEach((sec)=>{
                    var data = {}
                    sec.criterioList.forEach((cri)=>{
                        var foundScale =   cri.escalaList.find(x=> x.selected);
                        data = {
                            ddr_codigo : 0,
                            ddr_codddo : this.selectedGuiaDesempeno.codigo,
                            ddr_codrve : foundScale.rve_codigo,
                            ddr_inst : INSTRUMENTO,
                            ddr_codper: this.estudiantePerfil.codigo,
                            ddr_codusr_creador : 0 //this.$store.getters.getUserId
                        }
                        dataToSend.push(data)
                    })
                });
                await this.guiaDocenteService.createRevisionDesempeno(
                {
                    revision: dataToSend,
                    comentario: this.desempenoDocenteComentarioList
                });
                this.$toast.add({severity:'success', summary:'¡Éxito!', detail:"Se ha realizado con éxito", life: 5000});

                this.CURRENT_TAB = TAB.RESULT
            }catch(ex){
                var msg = ex;
                if(msg.response)
                    msg =  msg.response.data

                this.$toast.add({severity:'error', summary:'¡Error!', detail:msg, life: 5000});
            }
        },
        //utilities
        /**
         * Convertir un index a vocal
         */
        vowel(idx){
            return methods.integerToVowel(idx);
        },
        //Icon Scale
        satisfactionRateIco(idx,escala){
            if(escala.selected){
                return SatisfactionCheck
            }
            if(idx == 4) //bad
                return SatisfactionAngry
            else if (idx === 3 )
                return SatisfactionUpset
            else if(idx === 2)
                return SatisfactionNeutral
            else if (idx === 1)
                return SatisfactionHappy
            else
                return SatisfactionExcited
        }
    },

    watch:{
        indexSeccion:function(newVal){
            //****** Get preview value ******
            //restar Value
            this.comentario_logros = ""
            this.comentario_mejoras = ""
            //Get comments values
            if(newVal == -1) return;

            var indexExists  =  this.desempenoDocenteComentarioList.findIndex(x=> x.ddc_codrse == this.revSeccionEsquemaList[newVal].codseccion);
            if(indexExists >= 0){
                this.comentario_logros = this.desempenoDocenteComentarioList[indexExists].ddc_logro
                this.comentario_mejoras = this.desempenoDocenteComentarioList[indexExists].ddc_mejora
            }

            setTimeout(function () {
                    window.scrollTo(0, 0);
            },2);
        }
    },
    computed:{
        currentSeccion(){
            if(this.revSeccionEsquemaList.length == 0 || this.indexSeccion == -1)
                return []

            var current =  this.revSeccionEsquemaList[this.indexSeccion]

            var i  = 0; // correaltivo
            //this.revSeccionEsquemaList.forEach((item)=>{
                current.criterioList.forEach((cri)=>{
                    i++;
                    //add property for correlativo
                    if(!("correlativo" in cri))
                        cri["correlativo"] = i;

                    var selectedIndex = -1
                    cri.escalaList.forEach((esc)=>{
                        //add property for selected escala/criterio
                        if(!("selected" in esc)){
                            esc["selected"] =  false
                            esc["hidden"] = false
                        }

                        //Change value for selected in DataBase
                        if(this.desempenoDocenteRevisionList.length > 0){
                            selectedIndex =  this.desempenoDocenteRevisionList.findIndex(x=> x.ddr_codrve == esc.rve_codigo)
                            esc.selected =  selectedIndex != -1
                        }
                    })

                    //hidden the other data if is selected
                    var isSelectedData =  cri.escalaList.some(x=> x.selected);
                    if(isSelectedData){
                        cri.escalaList.forEach((esc) => {
                            esc.hidden =  esc.selected != true
                        })
                    }

                })
            //})
            return  current
        },

        isSaved(){
            //if items were saved
            var total = this.desempenoDocenteRevisionList.length ;
            return total > 0
        }
    },
    components:{
        AppFooter,
        AppProfile,
        SuccessAnimationIco :  SuccessAnimation,
        LayoutGridIco : LayoutGrid,
        ErrorPage
    }
}
</script>

<style lang="scss" scoped>
    .option {
        transition: 0.3s;
        cursor: pointer;
        border-radius: 25px !important;
        &:active {
            transform: scaleX(0.9);
        }
    }


</style>
<style>
    html {
        scroll-behavior: smooth;
        height: 100%; overflow:auto;
    }
    body { height: 100%; }
.p-inputtextarea {
    background-color: #F3F6F9;
    border-color: #F3F6F9;
    color: #3F4254;
}

.p-inputtextarea:active {
  background-color: #EBEDF3;
  border-color: #EBEDF3;
  color: #3F4254;
}
.p-inputtextarea::placeholder {
  color: #B5B5C3;
}
</style>
